.body {
    margin: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .left-login {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .left-login h1 {
    color: #77ffc0;
  }
  
  .right-login {
    width: 60vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-login {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 35px;
    background-color: rgba(49,89,117,255);
    border-radius: 20px;
    box-shadow: 0px 10px 40px #00000056;
  }
  .login-form{
    width: 75%;
  }
  .card-login h1 {
    color: white;
    font-weight: 800;
    margin: 0;
  }
  
  .chart{
    width: 80%;
  }
  
  
  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 0px;
  }
  
  .form-field {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 15px;
    background-color: #CCCED5;
    color: darkslategray;
    font-size: 12pt;
    box-shadow: 0px 10px 40px #00000056;
    box-sizing: border-box;
    outline: none;
  }
  
  .form-group label {
    color: #f0ffffde;
    margin-bottom: 10px;
  
  }
  
  .form-error {
    display: block;
    color: red;
    font-size: 0.8em;
  }
  
  .form-field ::placeholder {
    color: rgba(49,89,117,255);
  }
  
  .button {
    width: 100%;
    padding: 16px 0px;
    border: none;
    border-radius: 6px;
    outline: none;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 3px;
    cursor: pointer;
    margin-top: 15px;
    background: #B3DAF2;
    color: black;
  }
  
  @media (max-width: 950px){
    .card-login{
      width: 100%;
    }
  }
  
  @media (max-width: 600px){
    .body{
      flex-direction: column;
    }
  
    .left-login {
      width: 100%;
      height: auto;
    }
  
    .right-login {
      width: 100%;
      height: auto;
    }
  
    .card-login {
      width: 90%;
      
    }
  }
  